import React from "react";

import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";

import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";

import { SubTitleProps } from "../../contents/type";

const SubTitle: React.FC<SubTitleProps> = (props) => {
  const theme = useTheme();
  const isWideScreen = useMediaQuery(theme.breakpoints.up("md"));

  return (
    <Box>
      <Typography
        fontSize={isWideScreen ? "36px" : "24px"}
        fontWeight={400}
        lineHeight={1.25}
        margin={"0 0 20px"}
      >
        {props.text}
      </Typography>
    </Box>
  );
};

export default SubTitle;
