import Box from "@mui/material/Box";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import Typography from "@mui/material/Typography";
import React from "react";
import { ContentListProps } from "../../contents/type";

const ContentList: React.FC<ContentListProps> = (props) => {
  return (
    <>
      {props.contentList.map((content, i) => {
        return (
          <Box textAlign={"left"} key={i}>
            <Typography fontSize={"20px"} margin={"0 0 10px"} fontWeight={700}>
              {content.title}
            </Typography>
            <List
              component="ul"
              sx={{
                listStyleType: "disc",
                marginBlockStart: "1em",
                marginBlockEnd: "1em",
                marginInlineStart: "0px",
                marginInlineEnd: "0px",
                paddingInlineStart: "40px",
                marginBottom: "20px",
                paddingTop: "0px",
              }}
            >
              {content.items.map((item, i) => {
                return (
                  <ListItem
                    component="li"
                    sx={{
                      display: "list-item",
                      padding: "0",
                      marginTop: "5px",
                    }}
                    key={i}
                  >
                    <ListItemText>
                      <Typography fontWeight={700}>{item.title}</Typography>

                      {item.descriptions.map((description, i) => (
                        <Typography fontSize="14px" key={i}>
                          {" "}
                          {description}
                        </Typography>
                      ))}
                    </ListItemText>
                  </ListItem>
                );
              })}
            </List>
          </Box>
        );
      })}
    </>
  );
};

export default ContentList;
