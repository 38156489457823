import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import React from "react";
import { ListProps } from "../../contents/type";

const List: React.FC<ListProps> = (props) => {
  return (
    <Box textAlign={"left"}>
      {props.list.map((content, i) => {
        return (
          <Box key={i}>
            <Typography fontSize={"20px"} margin={"0 0 10px"} fontWeight={700}>
              {content.title}
            </Typography>
            {content.descriptions.map((description, i) => (
              <Typography fontSize="16px" marginBottom={"20px"} key={i}>
                {description}
              </Typography>
            ))}
          </Box>
        );
      })}
    </Box>
  );
};

export default List;
