import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Image from "next/image";
import React from "react";
import { ImagesProps } from "../../contents/type";

const Images: React.FC<ImagesProps> = (props) => {
  return (
    <Grid container spacing={4} alignItems={"start"}>
      {props.images.map((image, i) => {
        return (
          <Grid
            item
            xs={image.xs ? image.xs : 12}
            md={image.md ? image.md : 6}
            key={i}
          >
            <Box
              sx={{
                display: "flex",
                height: "100%",
                justifyContent: "center",
                alignItems: "center",
                flexWrap: "wrap",
              }}
            >
              <Typography
                fontSize={"20px"}
                fontWeight={700}
                lineHeight={"1.4"}
                margin={"0 0 10px"}
              >
                {image.title}
              </Typography>
              <Typography
                variant="h1"
                sx={{ flexGrow: 1 }}
                border={image.border}
              >
                <Image
                  src={image.src}
                  width={image.width}
                  height={image.height}
                  alt={""}
                />
              </Typography>
              {image.caption && (
                <Typography
                  fontSize={"12px"}
                  fontWeight={400}
                  lineHeight={"1.4"}
                  margin={"0 0 10px"}
                >
                  {image.caption}
                </Typography>
              )}
            </Box>
          </Grid>
        );
      })}
    </Grid>
  );
};

export default Images;
