import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import React from "react";
import { ContentProps } from "../contents/type";
import Boxes from "./content/Boxes";
import ContentList from "./content/ContentList";
import Descriptions from "./content/Descriptions";
import Elements from "./content/Elements";
import Images from "./content/Images";
import ListContent from "./content/List";
import SubTitle from "./content/SubTitle";
import Title from "./content/Title";

const Content: React.FC<ContentProps & { index: number }> = (props) => {
  const theme = useTheme();
  const isWideScreen = useMediaQuery(theme.breakpoints.up("md"));

  const backgroundColor =
    props.index % 2 === 0
      ? "#fff"
      : props.index % 4 === 1
      ? "#2B3E50"
      : "#eef4f7";
  const color =
    props.index % 2 === 0 ? "#333" : props.index % 4 === 1 ? "#fff" : "#333";

  return (
    <Box paddingTop={0}>
      <Box
        sx={{
          color: props.color ?? color,
          padding: props.padding
            ? props.padding(isWideScreen)
            : isWideScreen
            ? "150px 0"
            : "80px 0",
          backgroundImage: props.backgroundImage,
          backgroundColor: props.backgroundColor ?? backgroundColor,
          height: props.height ? props.height(isWideScreen) : undefined,
          backgroundPosition: "50%",
          backgroundSize: "cover",
          margin: 0,
          backgroundAttachment: "fixed",
          overflow: "hidden",
          position: "relative",
        }}
      >
        <Container
          maxWidth={"lg"}
          sx={{
            display: "flex",
            height: "100%",
            justifyContent: "center",
            alignItems: "center",
            flexWrap: "wrap",
            textAlign: "center",
            flexDirection: "column",
            maxWidth: props.maxWidth,
          }}
        >
          {props.contents?.map((content, i) => {
            switch (content.type) {
              case "Title":
                return <Title key={i} {...content.content} />;
              case "SubTitle":
                return <SubTitle key={i} {...content.content} />;
              case "Descriptions":
                return <Descriptions key={i} {...content.content} />;
              case "List":
                return <ListContent key={i} {...content.content} />;
              case "ContentList":
                return <ContentList key={i} {...content.content} />;
              case "Images":
                return <Images key={i} {...content.content} />;
              case "Elements":
                return <Elements key={i} {...content.content} />;
              case "Boxes":
                return <Boxes key={i} {...content.content} />;
            }
          })}
        </Container>
      </Box>
    </Box>
  );
};

export default Content;
