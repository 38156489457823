import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import React from "react";
import { ElementsProps } from "../../contents/type";

const Elements: React.FC<ElementsProps> = (props) => {
  return (
    <Box sx={{ width: props.width }}>
      {props.elements.map((element, i) => {
        return (
          <Box key={i}>
            {"description" in element ? (
              <Typography
                fontSize={element.fontSize}
                lineHeight={element.lineHeight}
                color={element.color}
                marginBottom={element.marginBottom}
              >
                {element.description}
              </Typography>
            ) : (
              <Box>{element}</Box>
            )}
          </Box>
        );
      })}
    </Box>
  );
};

export default Elements;
