import { GridSize } from "@mui/material/Grid";
import { Property } from "csstype";

export type Content =
  | {
      type: "Title";
      content: TitleProps;
    }
  | {
      type: "SubTitle";
      content: SubTitleProps;
    }
  | {
      type: "Descriptions";
      content: DescriptionsProps;
    }
  | {
      type: "List";
      content: ListProps;
    }
  | {
      type: "ContentList";
      content: ContentListProps;
    }
  | {
      type: "Images";
      content: ImagesProps;
    }
  | {
      type: "Elements";
      content: ElementsProps;
    }
  | {
      type: "Boxes";
      content: BoxesProps;
    };

type List = {
  title: string;
  descriptions: string[];
};

type ContentList = {
  title: string;
  items: {
    title: string;
    descriptions: string[];
  }[];
};

type Image = {
  title: string;
  src: string;
  width: number;
  height: number;
  caption?: string;
  border?: string;
  xs?: GridSize;
  md?: GridSize;
};
type Element =
  | {
      fontSize?: string;
      lineHeight?: number;
      color?: string;
      marginBottom?: string;
      description: string;
    }
  | React.ReactElement;

type Box = {
  title: string;
  titleOption?: {
    border?: string;
    fontSize?: string;
    color?: string;
    backgroundColor?: string;
  };
  image?: { src: string; width: number; height: number; isLoading?: boolean };
  descriptions: string[];
  caption?: string;
  xs?: GridSize;
  md?: GridSize;
  flexDirection?: Property.FlexDirection;
  element?: React.ReactElement;
  option?: {
    border?: string;
    fontSize?: string;
    color?: string;
    backgroundColor?: string;
  };
};

export type TitleProps = { text: string };
export type SubTitleProps = { text: string };
export type DescriptionsProps = {
  texts: string[];
  marginBottom?: string;
  fontSize?: string;
};
export type ListProps = { list: List[] };
export type ContentListProps = { contentList: ContentList[] };
export type ImagesProps = { images: Image[] };
export type ElementsProps = { elements: Element[]; width?: string };
export type BoxesProps = { boxes: Box[] };

export type ContentProps = {
  backgroundColor?: string;
  backgroundImage?: string;
  color?: string;
  padding?: (isWideScreen: boolean) => string;
  height?: (isWideScreen: boolean) => string;
  contents?: Content[];
  maxWidth?: string;
};

export const title = (title: string): Content => {
  return { type: "Title", content: { text: title } };
};
export const subTitle = (subTitle: string): Content => {
  return { type: "SubTitle", content: { text: subTitle } };
};
export const descriptions = (
  texts: string[],
  options?: {
    marginBottom?: string;
    fontSize?: string;
  }
): Content => {
  return {
    type: "Descriptions",
    content: {
      texts: texts,
      marginBottom: options?.marginBottom,
      fontSize: options?.fontSize,
    },
  };
};

export const list = (list: List[]): Content => {
  return {
    type: "List",
    content: { list: list },
  };
};

export const contentList = (contentList: ContentList[]): Content => {
  return {
    type: "ContentList",
    content: { contentList: contentList },
  };
};

export const images = (images: Image[]): Content => {
  return {
    type: "Images",
    content: { images: images },
  };
};

export const elements = (
  elements: Element[],
  options?: { width?: string }
): Content => {
  return {
    type: "Elements",
    content: { elements: elements, width: options?.width },
  };
};

export const boxes = (boxes: Box[]): Content => {
  return {
    type: "Boxes",
    content: { boxes: boxes },
  };
};
