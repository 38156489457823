import Box from "@mui/material/Box";
import CircularProgress from "@mui/material/CircularProgress";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Image from "next/image";
import React from "react";
import { BoxesProps } from "../../contents/type";

const Boxes: React.FC<BoxesProps> = (props) => {
  return (
    <Grid
      container
      spacing={4}
      alignItems={"start"}
      gridAutoColumns={"1fr"}
      gridAutoFlow={"column"}
    >
      {props.boxes.map((box, i) => {
        return (
          <Grid item xs={box.xs ? box.xs : 6} md={box.md ? box.md : 4} key={i}>
            <Box
              sx={{
                display: "flex",
                height: "100%",
                justifyContent: "center",
                alignItems: "center",
                flexWrap: "wrap",
                flexDirection: box?.flexDirection ?? "column",
                border: box.option?.border ?? "1px #333 solid",
                padding: "23px",
                backgroundColor: box.option?.backgroundColor ?? "white",
                borderRadius: "4px",
                color: "black",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  height: "100%",
                  justifyContent: "center",
                  alignItems: "center",
                  flexWrap: "wrap",
                  flexDirection: "column",
                  border: box.titleOption?.border,
                  color: box.titleOption?.color,
                  backgroundColor: box.titleOption?.backgroundColor,
                  borderRadius: "10px",
                  margin: "0 0 10px",
                }}
              >
                <Typography
                  fontSize={box.titleOption?.fontSize ?? "36px"}
                  fontWeight={700}
                  lineHeight={"1.4"}
                  margin={"4px 20px"}
                >
                  {box.title}
                </Typography>
              </Box>
              {box.image && (
                <Typography
                  variant="h1"
                  sx={{
                    flexGrow: 1,
                    margin: "24px",
                  }}
                >
                  {box.image.isLoading ? (
                    <CircularProgress size={100} />
                  ) : (
                    <Image
                      src={box.image.src}
                      width={box.image.width}
                      height={box.image.height}
                      alt={""}
                    />
                  )}
                </Typography>
              )}
              <Box sx={{ flexGrow: 1, textAlign: "left" }}>
                {box.descriptions.map((description, i) => {
                  return (
                    <Typography key={i} variant="h1" fontWeight={700}>
                      {description}
                    </Typography>
                  );
                })}
                {box.caption && (
                  <Typography
                    fontSize={"14px"}
                    fontWeight={400}
                    lineHeight={"1.4"}
                    margin={"10px 0 10px"}
                    textAlign={"left"}
                  >
                    {box.caption}
                  </Typography>
                )}
              </Box>
              <Box sx={{ margin: "1.5rem" }}>{box.element}</Box>
            </Box>
          </Grid>
        );
      })}
    </Grid>
  );
};

export default Boxes;
