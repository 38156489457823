import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import React from "react";
import { DescriptionsProps } from "../../contents/type";

const Descriptions: React.FC<DescriptionsProps> = (props) => {
  return (
    <Box textAlign={"left"} marginBottom={props.marginBottom}>
      {props.texts.map((description, i) => {
        return (
          <Typography key={i} fontSize={props.fontSize ?? undefined}>
            {description}
          </Typography>
        );
      })}
    </Box>
  );
};

export default Descriptions;
